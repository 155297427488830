//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-28:_5888,_8851,_9296,_2944,_5064,_6552,_3760,_2864;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-28')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-28', "_5888,_8851,_9296,_2944,_5064,_6552,_3760,_2864");
        }
      }catch (ex) {
        console.error(ex);
      }